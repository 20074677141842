<template>
    <div class="main_container">
        <div class="title_container">
            <div>每天新注册班级数量：</div>
            <div class="right_container">
                <!--                <el-date-picker-->
                <!--                        size="small"-->
                <!--                        v-model="value1"-->
                <!--                        type="date"-->
                <!--                        placeholder="选择日期"-->
                <!--                        style="margin-right: 20px">-->
                <!--                </el-date-picker>-->
                <el-radio-group v-model="radio" @change="getDate6">
                    <el-radio label="0">全部</el-radio>
                    <el-radio label="1">自定义班级</el-radio>
                    <el-radio label="2">官方班级</el-radio>
                </el-radio-group>
            </div>
        </div>
        <ve-line :data="chartData"></ve-line>

        <div class="title_container">班群画像</div>
        <ve-histogram :data="chartData2" :settings="chartSettings"></ve-histogram>

        <div class="title_container">首次创建班群用户身份：</div>
        <ve-histogram :data="chartData3" :settings="chartSettings3"></ve-histogram>

        <div class="title_container">
            <div>班级分布排序图：</div>
        </div>
        <ve-histogram :data="chartData4" :settings="chartSettings4"></ve-histogram>

        <div class="title_container">各年级布置作业频次:</div>
        <ve-histogram :data="chartData5" :settings="chartSettings5"></ve-histogram>


    </div>
</template>


<script>
    import {getDateFromTimeStamp2} from "@/utils/dateUtil";

    export default {
        name: "user",

        data() {

            return {
                // value1: '',//选择日期
                chartSettings: {
                    metrics: ['班群学生平均规模', '作业频次/周', '自学频次/周'],
                    dimension: ['日期']
                },
                chartSettings3: {
                    stack: {'用户': ['家长首建班群', '老师首建班群']}
                },
                chartSettings4: {
                    metrics: ['年级占比'],
                    dimension: ['年级']
                },
                chartSettings5: {
                    metrics: ['年级占比'],
                    dimension: ['年级']
                },
                radio: '0'
            }
        },
        computed: {
            // 每日用户登录次数
            chartData() {
                let rows = this.$store.state.data.data6.map((item) => {
                    let row = {};
                    row['日期'] = getDateFromTimeStamp2(item.time);
                    row['每天新注册班级数量'] = item.count;
                    return row;
                })
                let columns = ['日期', '每天新注册班级数量'];
                return {rows, columns}
            },
            // 班群画像
            chartData2() {
                let rows = this.$store.state.data.data7.map((item) => {
                    let row = {};
                    row['日期'] = getDateFromTimeStamp2(item.createTime);
                    row['班群学生平均规模'] = item.groupUserCount * 1.0 / item.groupCount;
                    row['作业频次/周'] = item.homeworkCount / item.groupCount;
                    row['自学频次/周'] = item.exerciseCount / item.groupCount;
                    return row;
                })
                let columns = ['日期', '班群学生平均规模', '作业频次/周', '自学频次/周'];
                return {rows, columns}
            },
            // 首次创建班群用户身份
            chartData3() {
                let rows = this.$store.state.data.data8.map((item) => {
                    let row = {};
                    row['日期'] = getDateFromTimeStamp2(item.createTime);
                    row['家长首建班群'] = item.studentCreateCount;
                    row['老师首建班群'] = item.teacherCreateCount;
                    return row;
                })
                let columns = ['日期', '家长首建班群', '老师首建班群'];
                return {rows, columns}
            },
            // 班级分布排序图
            chartData4() {
                let rows = this.$store.state.data.data9.map((item) => {
                    let row = {};
                    row['年级'] = item.gradeName;
                    row['年级占比'] = item.count;
                    return row;
                })
                let columns = ['年级', '年级占比'];
                return {rows, columns}
            },
            // 各年级布置作业频次
            chartData5() {
                let rows = this.$store.state.data.data10.map((item) => {
                    let row = {};
                    row['年级'] = item.gradeName;
                    row['年级占比'] = item.homeworkCount * 1.0 / item.groupCount;
                    return row;
                })
                let columns = ['年级', '年级占比'];
                return {rows, columns}
            },
        },
        mounted() {
            this.getDate6();
            this.getDate7();
            this.getDate8();
            this.getDate9();
            this.getDate10();
        },
        methods: {
            getDate6() {
                this.$store.dispatch("data/getData6", {standard: this.radio});
            },
            getDate7() {
                this.$store.dispatch("data/getData7", {});
            },
            getDate8() {
                this.$store.dispatch("data/getData8", {});
            },
            getDate9() {
                this.$store.dispatch("data/getData9", {});
            },
            getDate10() {
                this.$store.dispatch("data/getData10", {});
            }
        }
    }
</script>

<style scoped>
    .main_container {
        padding: 10px 20px 20px 20px;
    }

    .title_container {
        font-weight: bolder;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .right_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user_count {
        font-size: 18px;
        margin-left: 30px;
    }

</style>
